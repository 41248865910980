








































































































































































import axios from 'axios';
import { Vue, Component } from 'vue-property-decorator';
import { Ax } from '@/utils';
import {selectAllOnClick, staffTabAccess, store} from '../common';
import { Overlay } from '../components';
import I18n from '../I18n';
import { SingleOrgField } from '../organizations';
import { Comp, Org, Version } from '../types';
import { SingleVersionField } from '../versions';


// region Local types
/**
 * Данные получаемые из сервера
 */
interface IData {
    /**
     * ID
     */
    id: number | null;

    /**
     * Название на казахском
     */
    nameKk: string;

    /**
     * Название на русском
     */
    nameRu: string;

    /**
     * Признак "нет детей"
     */
    leaf: boolean;

    /**
     * ID родителя
     */
    parent: number | null;

    /**
     * Позиция для сортировки visibleRows
     */
     serialNumber: number ;
}

/**
 * Строка таблицы
 */
interface IRow extends Comp.TableTreeRow<IData, IRow> {
    /**
     * Ключи переводов ошибок для полей
     */
    errors: {
        /**
         * Ошибка поля "Название на казахском"
         */
        nameKk: string | null;

        /**
         * Ошибка поля "Название на русском"
         */
        nameRu: string | null;
    };

    /**
     * Введенные пользователем значения
     *
     * _Используются, чтоб не терялись ошибки_
     */
    inputValues: {};
}

/**
 * Изменение данных
 */
interface IDataChange {
    /**
     * Название на казахском
     */
    nameKk?: string;

    /**
     * Название на русском
     */
    nameRu?: string;
}

/**
 * Данные передаваемые на сервер для сохранения (привязка к родителю)
 */
interface ISavingDataWithParent {
    /**
     * ID
     */
    id: number | null;

    /**
     * Название на казахском
     */
    nameKk: string;

    /**
     * Название на русском
     */
    nameRu: string;

    /**
     * ID родителя
     */
    parent: number;
}

/**
 * Данные передаваемые на сервер для сохранения (привязка к ГУ)
 */
interface ISavingDataWithGa {
    /**
     * ID
     */
    id: number | null;

    /**
     * Название на казахском
     */
    nameKk: string;

    /**
     * Название на русском
     */
    nameRu: string;

    /**
     * Версия штатного расписания
     */
    version: number;
}

/**
 * Данные передаваемые на сервер для сохранения
 *
 * * `ISavingDataWithParent` - с привязкой к родителю
 * * `ISavingDataWithGa` - с привязкой к версии штатного расписания
 */
type TSavedData = ISavingDataWithParent | ISavingDataWithGa;
// endregion


// region Utils
const i18n = new I18n('modules.budget.staffing_table.ga_departments');

const copyData = (data: IData): IData => {
    return {
        id: data.id,
        nameKk: data.nameKk,
        nameRu: data.nameRu,
        leaf: data.leaf,
        parent: data.parent,
        serialNumber: data.serialNumber
    };
}
// endregion


@Component({
    components: {
        'org-field': SingleOrgField,
        'overlay': Overlay,
        'version-field': SingleVersionField,
    }
})
export default class Page extends Vue {
    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        // region Org, version
        this.$watch('selectedOrg', (selectedOrg: Org | null) => {
            store.org = selectedOrg;
            store.conditionExp = null
            this.selectedVersion = null;
        });

        this.$watch('selectedVersion', (selectedVersion: Version | null) => {
            store.version = selectedVersion;
            store.gaDepartmentList = []
            store.subDepartments = []
            if (selectedVersion === null) {
                this.rootRows = [];
            } else {
                this.reloadRows();
            }
        });
        // endregion


        // region Departments
        this.$watch('tableRenderingScheduled', (n: boolean) => {
            if (n) {
                setTimeout(() => {
                    this.tableRenderingScheduled = false;
                    this.rootRows = new Array(...this.rootRows);
                });
            }
        });

        this.$watch('selected', (n: (IRow | null), o: (IRow | null)) => {
            if (n !== null) {
                n.selected = true;
                this.applyChange(n);
            }
            if (o !== null) {
                o.selected = false;
                this.applyChange(o);
            }
        });

        this.$watch('movingRow', (n: IRow | null, o: IRow | null) => {
            if (n === null) {
                this.forbiddenParents = [];
            } else {
                this.selected = null;

                const forbiddenParents: IRow[] = [];
                const add = (forbiddenParent: IRow) => {
                    forbiddenParents.push(forbiddenParent);
                    if (forbiddenParent.children !== null) {
                        forbiddenParent.children.forEach(add);
                    }
                };
                add(n);

                if (n.parent !== null) {
                    forbiddenParents.unshift(n.parent);
                }

                this.forbiddenParents = forbiddenParents;

                this.applyChange(n);
            }

            if (o === null) {
                if (n === null) {
                    this.scheduleTableRendering();
                }
            } else {
                this.applyChange(o);
            }
        });
        // endregion
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        if (this.selectedVersion !== null) {
            this.reloadRows();
        }
    }
    // endregion


    // region Utils
    private i18n = i18n;

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
    private selectAllOnClick = selectAllOnClick;
    // endregion

    // Доступ к редактированию данных
    private isEditable = staffTabAccess.isEditable

    // region Org, version
    private selectedOrg: Org | null = store.org;

    private selectedVersion: Version | null = store.version;

    private get getVersionId (): number | null {
        return this.selectedVersion?.id ?? null
    }
    // endregion


    // region Departments
    private tableRenderingScheduled = false;

    private loadingItems = false;

    private get loading(): boolean {
        return (this.loadingItems);
    }

    private get fields(): Comp.TableFieldDef[] {
        return [
            // ID
            {
                key: 'data.id',
                label: this.i18n.translate('table_fields.id')
            },

            // Название на казахском
            {
                key: 'data.nameKk',
                label: this.i18n.translate('table_fields.name_kk')
            },

            // Название на русском
            {
                key: 'data.nameRu',
                label: this.i18n.translate('table_fields.name_ru')
            },

            // Действия
            {
                key: 'actions',
                label: ''
            }
        ];
    }

    private rootRows: IRow[] = [];

    private changedIndexRows: IRow[] = [];

    private get visibleRows(): IRow[] {
        const result: IRow[] = [];
        // Отсортировать корневые строки перед вызовом scan(). Метод sort() не создает новый объект Array,а производит сортировку переданного массива.
        this.rootRows.sort((a, b) => a.data.serialNumber - b.data.serialNumber);

        const scan = (rows: IRow[]) => {
            rows.forEach(row => {
                result.push(row);
                if (row.expanded && (row.children !== null)) {
                    row.children.sort((a, b) => (a.data.serialNumber - b.data.serialNumber));
                    scan(row.children);
                }
            });
        };
        scan(this.rootRows);
        this.changedIndexRows = result
        return result
    }

    private selected: IRow | null = null;

    private movingRow: IRow | null = null;

    private replaceRow: IRow | null = null;

    // noinspection JSMismatchedCollectionQueryUpdate
    private forbiddenParents: IRow[] = [];


    /**
     * Можно ли добавить строку
     */
    private get canAdd(): boolean {
        // Нельзя, если не выбрана организация
        if (this.selectedOrg === null) {
            return false;
        }

        // Нельзя при перемещении строки
        if (this.movingRow !== null) {
            return false;
        }

        // Проверка выбранной строки
        const selected = this.selected;
        if (selected !== null) {
            // Нельзя, если выбрана строка с детьми, которые еще не загружены
            if ((!selected.leaf) && (selected.children === null)) {
                return false;
            }
            // Нельзя, если выбрана еще не сохраненная строка
            if (selected.data.id === null) {
                return false;
            }
        }

        return true;
    }

    private canMove(): boolean {
        return (this.movingRow === null);
    }

    private canPaste(row: IRow): boolean {
        return (
            (this.movingRow !== null)
            && (this.movingRow !== row)
            && (this.forbiddenParents.indexOf(row) < 0)
            && (
                (row.leaf)
                || (row.children !== null)
            )
        );
    }

    private canPasteAtTop(row: IRow): boolean {
        return (this.movingRow === row);
    }

    // noinspection JSMethodCanBeStatic
    private canSave(row: IRow): boolean {
        return (!row.invalid) && (row.changed);
    }

    private canCancelChanges(row: IRow): boolean {
        return (row.changed || (this.movingRow === row));
    }

    // noinspection JSMethodCanBeStatic
    private canAddChild(row: IRow): boolean {
        return (row.data.id !== null);
    }

    // noinspection JSMethodCanBeStatic
    private canRemove(row: IRow): boolean {
        return (row.data.id !== null);
    }

    private canCancelReplace(): boolean {
        return (this.replaceRow !== null);
    }

    private doReplace(row: IRow): boolean {
        return (this.replaceRow !== null && this.replaceRow !== row && row.level === this.replaceRow.level);
    }


    private scheduleTableRendering() {
        this.tableRenderingScheduled = true;
    }

    private reloadRows() {
        if (this.loadingItems) {
            console.error('Cannot reload rows - another loading is running');
            return;
        }

        const org = this.selectedOrg;
        if (org === null) {
            console.error('Cannot reload rows - no organization selected');
            return;
        }

        const version = this.selectedVersion
        if (version === null) {
            console.error('Error: Failed to reload rows - the selected version is empty');
            return;
        }

        this.loadingItems = true;
        this.rootRows = [];
        this.selected = null;
        this.movingRow = null;

        Ax<IData[]>(
            {
                url: `/api/budget/staffing_table/gu-departments/version/${version.id}/roots`,
                method: 'GET',
                responseType: 'json'
            },
            data => {
                data.forEach(item => {
                    item.parent = null;
                    const row: IRow = {
                        id: String(item.id),
                        data: item,
                        original: copyData(item),
                        level: 0,
                        leaf: item.leaf,
                        changed: false,
                        invalid: false,
                        selected: false,
                        errors: {
                            nameKk: null,
                            nameRu: null
                        },
                        inputValues: {},
                        parent: null,
                        children: null,
                        _rowVariant: null,
                        expanded: false
                    };
                    this.rootRows.push(row);
                });
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_departments'), error.toString()),
            () => {
                this.loadingItems = false;
            }
        );
    }

    private applyChange(row: IRow, change?: IDataChange) {
        if (change) {
            if (change.nameKk !== undefined) {
                row.data.nameKk = change.nameKk;
            }
            if (change.nameRu !== undefined) {
                row.data.nameRu = change.nameRu;
            }
        }

        row.errors.nameKk = null;
        row.errors.nameRu = null;

        if (row.data.nameKk.trim().length === 0) {
            row.errors.nameKk = 'modules.budget.staffing_table.ga_departments.error.empty_field';
        }
        if (row.data.nameRu.trim().length === 0) {
            row.errors.nameRu = 'modules.budget.staffing_table.ga_departments.error.empty_field';
        }

        row.invalid = (
            (typeof row.errors.nameKk === 'string')
            || (typeof row.errors.nameRu === 'string')
        );

        const original = row.original;
        if (original === null) {
            row.changed = true;
        } else {
            row.changed = (
                (row.data.nameKk !== original.nameKk)
                || (row.data.nameRu !== original.nameRu)
                || (row.data.parent !== original.parent)
            );
        }

        if (this.movingRow === row) {
            row._rowVariant = 'info';
        } else if (row.selected) {
            row._rowVariant = 'primary';
        } else if (row.invalid) {
            row._rowVariant = 'danger';
        } else if (row.changed) {
            row._rowVariant = 'success';
        } else {
            row._rowVariant = null;
        }

        this.scheduleTableRendering();
    }

    private addRow(parentRow?: IRow) {
        if (!this.canAdd) {
            return;
        }

        const row: IRow = {
            id: String(new Date().getTime()),
            data: {
                id: null,
                nameKk: '',
                nameRu: '',
                leaf: true,
                parent: null,
                serialNumber: 0
            },
            level: 0,
            leaf: true,
            changed: false,
            invalid: false,
            selected: false,
            errors: {
                nameKk: null,
                nameRu: null
            },
            inputValues: {},
            parent: null,
            children: null,
            expanded: false,
            original: null,
            _rowVariant: null
        };

        let currentParentRow: IRow | null;
        if (parentRow === undefined) {
            currentParentRow = this.selected;
        } else {
            currentParentRow = parentRow;
        }

        if (currentParentRow !== null) {
            if ((!currentParentRow.leaf) && (currentParentRow.children === null)) {
                return;
            }

            const selectedId = currentParentRow.data.id;
            if (selectedId === null) {
                return;
            }

            row.data.parent = selectedId;
            row.level = currentParentRow.level + 1;
            row.parent = currentParentRow;

            if (currentParentRow.children === null) {
                currentParentRow.children = [];
            }
            currentParentRow.children.unshift(row);
            currentParentRow.leaf = false;
            currentParentRow.expanded = true;
        } else {
            this.rootRows.unshift(row);
        }

        this.applyChange(row);
    }

    private toggleRowExpanded(e: Event, row: IRow) {
        e.preventDefault();
        if ((row.children !== null) && (row.children.length > 0)) {
            if (row.expanded) {
                const collapse = (collapsedRow: IRow) => {
                    collapsedRow.expanded = false;
                    if (collapsedRow.children !== null) {
                        collapsedRow.children.forEach(collapse);
                    }
                };
                collapse(row);
            } else {
                row.expanded = true;
            }

            this.scheduleTableRendering();
        } else if (!row.leaf) {
            const id = row.data.id;
            if (id === null) {
                return;
            }

            if (this.loadingItems) {
                return;
            }

            this.loadingItems = true;
            Ax<IData[]>(
                {
                    url: `/api/budget/staffing_table/gu-departments/parent/${id}`
                },
                data => {
                    row.expanded = true;
                    const rowChildren: IRow[] = [];
                    row.children = rowChildren;

                    data.forEach(item => {
                        item.parent = id;

                        const childRow: IRow = {
                            id: String(item.id),
                            data: item,
                            original: copyData(item),
                            level: row.level + 1,
                            leaf: item.leaf,
                            changed: false,
                            invalid: false,
                            selected: false,
                            errors: {
                                nameKk: null,
                                nameRu: null
                            },
                            inputValues: {},
                            parent: row,
                            children: null,
                            expanded: false,
                            _rowVariant: null
                        };

                        rowChildren.push(childRow);
                    });

                    this.scheduleTableRendering();

                    if (this.selected === row) {
                        this.selected = null;
                        setTimeout(() => {
                            this.selected = row;
                        });
                    }
                },
                error => this.toast('danger', this.i18n.translate('error.cannot_load_departments'), error.toString()),
                () => {
                    this.loadingItems = false;
                }
            );
        }
    }

    private toggleRowSelected(row: IRow) {
        if (this.selected === row) {
            this.selected = null;
        } else {
            this.selected = row;
        }
    }

    private startReplaceRow(row: IRow) {
        this.replaceRow = row;
        row._rowVariant = 'info';
    }

    private cancelReplaceRow() {
        if (this.replaceRow) {
            this.replaceRow._rowVariant = null;
            this.replaceRow = null;
        }
    }


    private replaceRowPosition(row: IRow) {

        const upDateGuDepartments = new Map<number, number>();

        if (this.replaceRow !== null) {
            const indexStartRow = this.changedIndexRows.indexOf(this.replaceRow);
            const indexReplaceRow = this.changedIndexRows.indexOf(row);

            const maxIndex = Math.max(indexStartRow, indexReplaceRow)
            const minIndex = Math.min(indexStartRow, indexReplaceRow)

            this.changedIndexRows.splice(indexStartRow, 1);
            if (indexStartRow > indexReplaceRow) {
                this.changedIndexRows.splice(minIndex, 0, this.replaceRow);
            } else {
                this.changedIndexRows.splice(maxIndex - 1, 0, this.replaceRow);
            }

            this.changedIndexRows.forEach((Row, indexRow) => {
                if ((this.replaceRow?.data.parent === Row.data.parent)) {
                    Row.data.serialNumber = indexRow
                    upDateGuDepartments.set(Row.data.id!!, indexRow)
                }
            });

            this.savePosition(upDateGuDepartments)
            this.cancelReplaceRow()
        }
    }

    private savePosition(guDepartmentsMap: Map<number, number>) {
        const guDepartmentsObj = Object.fromEntries(guDepartmentsMap);
        axios({
            url: "/api/budget/staffing_table/gu-department-sort",
            method: "POST",
            data: guDepartmentsObj,
        }).catch((error) => {
            this.toast("danger", this.i18n.translate(`error.cannot_save_serialNumber`), error.toString());
        })
    }

    private startRowMoving(row: IRow) {
        this.movingRow = row;
    }

    private pasteRow(parentRow?: IRow) {
        const movingRow = this.movingRow;
        if (movingRow === null) {
            return;
        }

        if ((parentRow !== undefined) && (!parentRow.leaf) && (parentRow.children === null)) {
            return;
        }

        const oldParent = movingRow.parent;
        const rootIndex = this.rootRows.indexOf(movingRow);
        const updateHierarchy = () => {
            const newParent = movingRow.parent;

            if (newParent !== oldParent) {
                if ((oldParent !== null) && (oldParent.children !== null)) {
                    const index = oldParent.children.indexOf(movingRow);
                    if (index >= 0) {
                        oldParent.children.splice(index, 1);
                        if (oldParent.children.length === 0) {
                            oldParent.children = null;
                            oldParent.leaf = true;
                            this.applyChange(oldParent);
                        }
                    }
                }

                if (newParent === null) {
                    if (rootIndex < 0) {
                        this.rootRows.unshift(movingRow);
                    }
                } else {
                    if (newParent.children === null) {
                        newParent.children = [];
                    }
                    newParent.children.unshift(movingRow);
                    newParent.leaf = false;
                    newParent.expanded = true;
                    this.applyChange(newParent);

                    if (rootIndex >= 0) {
                        this.rootRows.splice(rootIndex, 1);
                    }
                }
            }
        };

        if (parentRow === undefined) {
            movingRow.data.parent = null;
            movingRow.level = 0;
            movingRow.parent = null;
        } else {
            const parentId = parentRow.data.id;
            if (parentId === null) {
                return;
            }

            movingRow.data.parent = parentRow.data.id;
            movingRow.level = parentRow.level + 1;
            movingRow.parent = parentRow;
        }

        updateHierarchy();

        const updateChildren = (row: IRow) => {
            if (row.children !== null) {
                row.children.forEach(child => {
                    child.level = row.level + 1;
                    updateChildren(child);
                });
            }
        };
        updateChildren(movingRow);

        this.movingRow = null;
        this.applyChange(movingRow);
    }

    private save(row: IRow) {
        if (row.invalid || (!row.changed)) {
            return;
        }
        if (this.loadingItems) {
            return;
        }

        const versionId = this.getVersionId
        if (versionId === null) {
            console.error('Error: Failed to save savedData - the versionId is null');
            return;
        }

        let savedData: TSavedData | undefined;
        if (row.data.parent !== null) {
            savedData = {
                parent: row.data.parent,
                id: row.data.id,
                nameKk: row.data.nameKk,
                nameRu: row.data.nameRu
            };
        } else {
            savedData = {
                version: versionId,
                id: row.data.id,
                nameKk: row.data.nameKk,
                nameRu: row.data.nameRu
            };
        }

        // noinspection PointlessBooleanExpressionJS,JSIncompatibleTypesComparison
        if (savedData === undefined) {
            return;
        }

        this.loadingItems = true;

        Ax<number>(
            {
                url: '/api/budget/staffing_table/gu-department',
                method: 'POST',
                data: savedData,
                responseType: 'json'
            },
            id => {
                row.data.id = id;
                row.original = copyData(row.data);
                this.applyChange(row);

                this.toast(
                    'success',
                    this.i18n.translate('department_saved'),
                    `ID - ${id}`
                );
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_save_ga_department'), error.toString()),
            () => {
                this.loadingItems = false;
            }
        );
    }

    private cancelChanges(row: IRow) {
        if (this.movingRow === row) {
            this.movingRow = null;
        } else {
            const original = row.original;
            if (original === null) {
                const parentRow = row.parent;
                if ((parentRow !== null) && (parentRow.children !== null)) {
                    const index = parentRow.children.indexOf(row);
                    if (index >= 0) {
                        parentRow.children.splice(index, 1);
                        if (parentRow.children.length === 0) {
                            parentRow.children = null;
                            parentRow.leaf = true;
                        }
                        this.applyChange(parentRow);
                    }
                }

                const rootIndex = this.rootRows.indexOf(row);
                if (rootIndex >= 0) {
                    this.rootRows.splice(rootIndex, 1);
                    this.scheduleTableRendering();
                }
            } else {
                row.data.nameKk = original.nameKk;
                row.data.nameRu = original.nameRu;

                if (row.data.parent !== original.parent) {
                    row.data.parent = original.parent;

                    let oldParent: IRow | undefined;
                    const currentParent = row.parent;

                    if (original.parent !== null) {
                        const findParent = (rows: IRow[]) => {
                            for (const currentRow of rows) {
                                if (currentRow.data.id === original.parent) {
                                    oldParent = currentRow;
                                    break;
                                }

                                if (currentRow.children !== null) {
                                    findParent(currentRow.children);
                                }

                                if (oldParent !== undefined) {
                                    break;
                                }
                            }
                        };
                        findParent(this.rootRows);
                    }

                    if (oldParent !== currentParent) {
                        if (currentParent === null) {
                            const index = this.rootRows.indexOf(row);
                            if (index >= 0) {
                                this.rootRows.splice(index, 1);
                                this.scheduleTableRendering();
                            }
                        } else {
                            if (currentParent.children !== null) {
                                const index = currentParent.children.indexOf(row);
                                if (index >= 0) {
                                    currentParent.children.splice(index, 1);
                                    if (currentParent.children.length === 0) {
                                        currentParent.children = null;
                                        currentParent.leaf = true;
                                        currentParent.expanded = false;
                                    }
                                    this.applyChange(currentParent);
                                }
                            }
                        }

                        if (oldParent === undefined) {
                            this.rootRows.unshift(row);
                            row.level = 0;
                            row.parent = null;
                        } else {
                            if (oldParent.children === null) {
                                oldParent.children = [];
                            }
                            oldParent.children.unshift(row);
                            oldParent.leaf = false;
                            oldParent.expanded = true;
                            this.applyChange(oldParent);

                            row.level = oldParent.level + 1;
                            row.parent = oldParent;
                        }
                    }

                    const updateChildren = (updatedRow: IRow) => {
                        if (updatedRow.children !== null) {
                            updatedRow.children.forEach(child => {
                                child.level = updatedRow.level + 1;
                                updateChildren(child);
                            });
                        }
                    };
                    updateChildren(row);
                }

                this.applyChange(row);
            }
        }
    }

    private remove(row: IRow) {
        if (this.loadingItems) {
            return;
        }

        const id = row.data.id;
        if (id === null) {
            return;
        }

        let depName: string;
        if (this.$i18n.locale.trim() === 'kk') {
            depName = row.data.nameKk;
        } else {
            depName = row.data.nameRu;
        }

        this.$bvModal.msgBoxConfirm(
            this.i18n.translate('confirm_deletion', [ depName, id ]),
            {
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: this.i18n.translate('delete'),
                cancelTitle: this.i18n.translate('cancel')
            }
        )
            .then(userChoice => {
                if (userChoice) {
                    if (this.loadingItems) {
                        return;
                    }
                    this.loadingItems = true;

                    if (this.movingRow === row) {
                        this.movingRow = null;
                    }
                    if (this.selected === row) {
                        this.selected = null;
                    }

                    Ax<any>(
                        {
                            url: `/api/budget/staffing_table/gu-department/${id}`,
                            method: 'DELETE'
                        },
                        () => {
                            this.toast(
                                'success',
                                this.i18n.translate('department_removed'),
                                `"${depName}" (${this.i18n.translate('table_fields.id')} - ${id})`
                            );

                            const parentRow = row.parent;
                            if ((parentRow !== null) && (parentRow.children !== null)) {
                                const index = parentRow.children.indexOf(row);
                                if (index >= 0) {
                                    parentRow.children.splice(index, 1);
                                    if (parentRow.children.length === 0) {
                                        parentRow.children = null;
                                        parentRow.leaf = true;
                                        this.applyChange(parentRow);
                                    }
                                }
                            }

                            const rootIndex = this.rootRows.indexOf(row);
                            if (rootIndex >= 0) {
                                this.rootRows.splice(rootIndex, 1);
                            }

                            this.scheduleTableRendering();
                        },
                        (error) => {
                            console.error(error)
                            this.toast('danger', this.i18n.translate('error.cannot_delete_department'), this.i18n.translate('error.cannot_delete_department_has_referenced'))},
                        () => {
                            this.loadingItems = false;
                        }
                    );
                }
            });
    }


    private onRowClick(row: IRow) {
        if (this.movingRow === null) {
            this.toggleRowSelected(row);
        } else if ((this.movingRow !== row) && (this.forbiddenParents.indexOf(row) < 0) && ((row.leaf) || (row.children !== null))) {
            this.pasteRow(row);
        }
    }
    // endregion
}
